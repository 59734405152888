import React from 'react';
import { FindifyRecommendations } from '../../../Findify';
import MaxWidth from '../../../Layout/MaxWidth';
import SectionWrapper from '../../../Layout/SectionWrapper';

const FindifyRecommendationsBlok = props => {
  if (!props?.recIdString) return null;
  return (
    <SectionWrapper>
      <MaxWidth>
        <FindifyRecommendations recIdString={props.recIdString} />
      </MaxWidth>
    </SectionWrapper>
  );
};

export default FindifyRecommendationsBlok;
